var Grid = {
	"pos" : "",
	"comment" : true
}
var grids = [];
var gridBlocks = [];
var randomArry = [0,0,0,1];
var g = {
	init: function(){
		this.nav();
		this.gridSet();
	},
	gridSet: function(){
		var isRandom = false,
			count 	= 0;
		$("div[data-wrap-grid]").each(function(i){
			if($(this).find("ul").hasClass("random")){
				isRandom = true;
				shuffle(randomArry);
			}
			var even = true;
			$("ul li", this).each(function(j){
				var $this = $(this);
				var _g = object(Grid);
				var $this = $(this);
				if(j%5 === 0){
					if(even){
						even = false;
					}else{
						even = true;
					}
				}else{
					if(even){
						if(j%2 === 0){
							$this.addClass("ml");
						}
					}else{
						if(j%2 === 1){
							$this.addClass("ml");
						}
					}
				}
				if($(this).find(".cmt").length > 0){
					_g.comment = true;
					var attrcls = $this.find(".cmt").attr("class");
					var clsary = attrcls.split(" ");
					clsary.some(function(v,i){
						if(v === "cmt"){
							clsary.splice(i, 1);
						}
					});
					_g.pos = clsary[0];
				}else{
					_g.comment = false;
				}
				//ランダムの大きさの場合
				//console.log(count)
				if(isRandom){
					if(randomArry[count] === 1){
						$this.addClass("b");
					}
				}
				count++;
				if(count > 3){
					count = 0;
					shuffle(randomArry);
				}
			});
		});
		$(".cmt").each(function(i){
			var cls = $(this).attr("class");
			var clsary = cls.split(" ");
			clsary.some(function(v,i){
				if(v === "cmt"){
					clsary.splice(i, 1);
				}
			})
			
			var maxY = 15;
			var maxX = 10;
			if(cls.indexOf("lt") > -1){
				var _x = randRange(-maxX/2,maxX);
				var _y = randRange(0, maxY);
			}else if(cls.indexOf("lm") > -1){
				var _x = randRange(-maxX,maxX);
				var _y = randRange(-maxY, maxY);
			}else if(cls.indexOf("lb") > -1){
				var _x = randRange(-maxX/2,maxX);
				var _y = randRange(-maxY, maxY/3);
			}else if(cls.indexOf("rt") > -1){
				var _x = randRange(-maxX, maxX/2);
				var _y = randRange(0, maxY);
			}else if(cls.indexOf("rm") > -1){
				var _x = randRange(-maxX, maxX/2);
				var _y = randRange(-maxY, maxY);
			}else if(cls.indexOf("rb") > -1){
				var _x = randRange(-maxX, maxX/2);
				var _y = randRange(-maxY, maxY/3);
			}
			
			//console.log(_x, _y)
			$(this).css({
				"transform" : "translate3d(" + _x + "% ," + _y + "% ,0)"
			});
			/*$(this).css({
				"margin" : _y + "% 0 0 " + _x + "%"
			});*/
		});
	},
	nav: function(){
		$(document).on("keyup", function(e){
			console.log(e.keyCode);
			var code = e.keyCode;
			var cls = "";
			if(code === 48){
				cls = "xs";
			}else if(code === 49){
				cls = "s";
			}else if(code === 50){
				cls = "m";
			}else if(code === 51){
				cls = "l";
			}else if(code === 52){
				cls = "xl";
			}else{
				cls = "xs"
			}
			$("div[data-wrap-grid]").removeClass().addClass(cls);
		})
	}
}
function shuffle(array) {
	var n = array.length, t, i;
	while (n) {
		i = Math.floor(Math.random() * n--);
		t = array[n];
		array[n] = array[i];
		array[i] = t;
	}
	return array;
}
var randRange = function (min, max) {
	return Math.floor(Math.random() * (max - min + 1) + min);
};
